import React from "react"
import SEO from "../components/Seo"
import Container from "../components/Container"
import Belt from "../components/Belt"
import { Title, Quote } from "../components/typography"
import {
  Container as StaticBlockContainer,
  StaticBlock,
} from "../components/typography/StaticBlock"
import { FigureContainer, Figure } from "../components/Figure"
import hands_bw from "../images/belt/hands_bw.jpg"
import globuliCase from "../images/belt/globuliCase.jpg"
import girlBalloon from "../images/illustrations/girlBalloon.jpg"
import krankheiten from "../images/illustrations/krankheiten.jpg"

const BehandlungPage = () => (
  <>
    <SEO
      title="Angebot"
      keywords={[
        "Gesundheit",
        "Beschwerden",
        "Alternativmedizin",
        "Eigenständige Heilmethode",
        "Chronische und akute Krankheiten",
        "HALSSCHMERZEN",
        "SCHLAFPROBLEME",
        "RÜCKENSCHMERZEN",
        "EKZEM",
        "HUSTEN",
        "ZAHNEN",
        "DEPRESSION",
        "STRESS",
        "ADHS",
        "RHEUMA",
        "HEUSCHNUPFEN",
        "BLASENENTZÜNDUNG",
        "ASTHMA",
        "WECHSELJAHRE",
        "Medikamente",
        "Jedes Alter",
        "Erwachsene, Neugeborene, Kinder, Schwangere",
        "Ganz sich selbst sein",
        "Unbewusst",
        "Frei zu leben",
        "Situationen im Leben, die sich immer wiederholen",
        "Glaubenssätze",
        "Neue Möglichkeiten im hier und jetzt",
      ]}
      description="Die Homöopathie gehört zur Alternativmedizin und hat ein breites Wirkungsspektrum. Sie kann bei chronische und aktue Krankheiten angewendet werden. Sie kann auch komplementär zur schulmedizinischen Behandlung und bei gleichzeitiger Einnahme von Medikamente angewendet werden."
    />
    <Belt leftImage={hands_bw} rightImage={globuliCase} />
    <Container>
      <Quote cite="Gottfried Keller">
        Wenn wir unsere Aufmerksamkeit auf Gesundheit konzentrieren und auf
        alles Schöne, dann verändern sich unser Verhalten und unser Körper in
        diese Richtung.
      </Quote>
      <StaticBlockContainer>
        <StaticBlock>
          <Title>ANWENDUNGSBEREICHE</Title>
          <p>
            In der Praxis werde ich oft gefragt, ob man eine bestimmte
            Beschwerde auch mit der Homöopathie behandeln kann. Die Homöopathie
            gehört zur Alternativmedizin und ist eine eigenständige Heilmethode.
            Als solche bietet sie ein breites Wirkungsspektrum an und kann bei
            chronischen und akuten Krankheiten &mdash; vom Asthma bis zum Zahnen
            &mdash; angewendet werden. Gerne gebe ich Ihnen Auskunft, ob Ihre
            Beschwerden mit der Homöopathie behandelbar sind. Die homöopathische
            Behandlung kann auch komplementär zur schulmedizinischen Behandlung
            und bei gleichzeitiger Einnahme von Medikamenten angewendet werden.
          </p>
          <p>
            Die Homöopathie eignet sich für jedes Alter, von Jung bis Alt. Wie
            Hahnemann gesagt hat, wirkt die Homöopathie sanft, schnell und
            dauerhaft. Deshalb sind homöopathische Arzneien auch für Neugeborene
            und Schwangere geeignet.
          </p>
          <FigureContainer>
            <Figure
              src={krankheiten}
              alt="HALSSCHMERZEN, SCHLAFPROBLEME, RÜCKENSCHMERZEN, EKZEM, HUSTEN, ZAHNEN, DEPRESSION, STRESS, ADHS, RHEUMA, HEUSCHNUPFEN, BLASENENTZÜNDUNG, ASTHMA, WECHSELJAHRE"
            />
          </FigureContainer>
        </StaticBlock>
        <StaticBlock>
          <Title>GANZ SICH SELBST SEIN</Title>
          <p>
            Gibt es Situationen in Ihrem Leben, die sich immer wiederholen? Gibt
            es Dinge, die Sie unbewusst machen, aber eigentlich ganz anders
            machen möchten? Oder fühlen Sie sich nicht ganz frei, einfach so zu
            sein, wie Sie sind?
          </p>
          <p>
            Oft sind es früh erlernte Bewähltigungsstrategien und Glaubenssätze,
            welche uns hindern, als Erwachsenen frei zu leben. Im Gespräch gehen
            wir diesen auf den Grund und suchen mit unterschiedlichen Technicken
            (Bilder und konkrete Übungen) nach neuen Möglichkeiten im hier und
            jetzt, ganz sich selbst zu sein. Die homöopathischen Arzneien können
            diesen Prozess unterstützen, indem sie Kraft und einen Boden unter
            den Füssen geben. Die Reise beginnt mit dem Entscheid, etwas
            verändern zu wollen. Wie Ghandi gesagt hat „Sei du selbst die
            Veränderung, die du dir wünschst für diese Welt“. Ich begleite Sie
            gerne auf diesem spannenden Weg, wieder sich selbst zu finden und im
            Hier und Jetzt, ganz sich selbst zu sein.
          </p>
          <FigureContainer>
            <Figure src={girlBalloon} alt="Sich selbst sein" />
          </FigureContainer>
        </StaticBlock>
      </StaticBlockContainer>
    </Container>
  </>
)

export default BehandlungPage
